import Vue from 'vue';

Vue.mixin({

    methods: {
        showAlert(msg) {
            this.$buefy.dialog.alert({
                title: 'theREMworld',
                message: msg,
                confirmText: 'Okay',
                type: 'is-primary',
            })
        },

        showToastSuccess(msg) {
            this.$buefy.toast.open({
                duration: 3000,
                message: msg,
                type: 'is-success'});
        },

        showToastError(msg) {
            this.$buefy.toast.open({
                duration: 3000,
                message: msg,
                position: 'is-bottom',
                type: 'is-danger'});
        },

        logConsole(hdr, data) {
            if (this.$appMode.toLowerCase() !== 'prod') {
                console.log(hdr, data);
            }
        },

    }
})
