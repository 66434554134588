<template>
    <section>
        <div>
            <nav-bar @routeLink='showComponent'></nav-bar>
        </div>
        <component :is='compName'></component>
    </section>
</template>

<script>
    import NavBar from '../auth/NavBar.vue';

    export default ({
        
        components: {
            'nav-bar': NavBar,
        },

        data() {
            return {
                compName: ()=> import('./ManageSymbols.vue'),

                adminComps: [
                    { name: '/', header: 'Home', comp: ()=> import('./ManageSymbols.vue') },
                    { name: 'banners', header: 'Header Banners', comp: ()=> import('./HomepageBanners.vue') },
                    { name: 'rowbanners', header: 'Header Banners', comp: ()=> import('./ManageRowBanners.vue') },
                    { name: 'manageqotd', header: 'Manage QOTD', comp: ()=> import('./ManageQotd.vue') },
                    { name: 'managepages', header: 'Manage Pages', comp: ()=> import('./ManagePages.vue') },
                    { name: 'manageposts', header: 'Manage Posts', comp: ()=> import('./ManagePosts.vue') },
                ]
            }
        },

        methods: {
            showComponent(rLink) {
                //alert(rLink + ' -AH- ' + rComp);
                //this.compName = ()=> import(rComp);
                for (let x=0; x < this.adminComps.length; ++x) {
                    if (rLink.toLowerCase() == this.adminComps[x].name.toLowerCase()) {
                        //this.compName = ()=> import(this.adminComps[x].comp);
                        this.compName = this.adminComps[x].comp;
                    }
                }
            }

        },

    });

</script>

<style scoped>

</style>