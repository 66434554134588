<template>
    <section>
        <div class='container detail-box'>
            <div class='box'>
                <b-button class="btnmodal fa fa-times" @click='closeDetailView'></b-button>
                <b-tag type='is-info' class='top-right'> {{ showCount }} </b-tag>
                <div class='content content-box'>
                    <b-image :src='logoImage' alt="theREMworld Logo" class='imgLogo'></b-image>
                    <h1 class='is-capitalized'>{{ selected.postTitle }}</h1>
                      <p v-html='selected.postText'></p>
                </div>
            </div>
            <div>
                <b-button class='prev-post is-capitalized' v-if='showPrev' @click='showPrevPost'> {{ prevPostName }} </b-button>
                <b-button class='next-post is-capitalized' v-if='showNext' @click='showNextPost'> {{ nextPostName }} </b-button>
            </div>
        </div>
    </section>
</template>

<script>

export default ({
    props: ['rowNum', 'postList'],

    emits: ['close'],

    metaInfo: {
        title: 'View details',
        meta: [
            {name: 'description', content: 'Detailed description of your selection'}
        ]
    },

    data() {
        return {
            logoImage: this.$assetsPath + "logo.png",
            posts: this.postList,
            currentPost: this.rowNum,
            nextPost: 0,
            prevPost: 0,
            selected: {},
        }
    },

    computed: {
        prevPostName() {
            let returnString = 'Nothing here...';
            if (this.prevPost >= 0) {
                returnString = '<< ' + this.posts[this.prevPost]['postTitle'];
            }
            return (returnString);
        },

        nextPostName() {
            let returnString = 'Nothing here...';
            if (this.nextPost < this.posts.length) {
                returnString = this.posts[this.nextPost]['postTitle'] + ' >>';
            }
            return (returnString);
        },

        showNext() {
            return (this.nextPost < this.posts.length);
        },

        showPrev() {
            return (this.prevPost >= 0);
        },

        showCount() {
            return ((this.currentPost + 1) + ' / ' + this.posts.length);
        }

    },

    methods: {
        showNextPost() {
            this.prevPost = this.currentPost;
            ++this.currentPost;
            this.selected = this.posts[this.currentPost];
            this.nextPost = this.currentPost + 1;
        },

        showPrevPost() {
            this.nextPost = this.currentPost;
            --this.currentPost;
            this.selected = this.posts[this.currentPost];
            this.prevPost = this.currentPost - 1;
        },

        closeDetailView() {
            this.$emit('close');
        }
    },

    mounted() {
        this.selected = this.posts[this.currentPost];
        this.nextPost = this.currentPost + 1;
        this.prevPost = this.currentPost - 1;
    },
})

</script>

<style scoped>

    .detail-box {
        padding: 25px;
        margin: 25px;
    }

    .content-box {
        min-height: 350px;
    }

    .imgLogo {
        float: left;
        margin: 5px;
    }

    .prev-post {
        margin: 5px;
        float: left;
    }

    .next-post {
        margin: 5px;
        float: right;
    }

    .top-right {
        float: right;
    }

</style>
