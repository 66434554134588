import Vue from 'vue';
import App from './App.vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueMeta from 'vue-meta';
//import Ads from 'vue-google-adsense';

import VueRouter from 'vue-router';
import { routes } from './routes.js';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import './style.scss'

import '@/mixins/commonMixins'; //Mixins for common use

// Font awesome libraries and components
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHatWizard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './registerServiceWorker'
library.add(faHatWizard);
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Global variables
Vue.config.productionTip = false
Vue.prototype.$http = axios;

// Read config settings
var CONFIG = require('./config.json');
Vue.prototype.$baseURL = CONFIG.baseURL;
Vue.prototype.$apiURL = CONFIG.apiURL;
Vue.prototype.$compURL = CONFIG.compURL;
//Vue.prototype.$srcURL = CONFIG.srcURL;
Vue.prototype.$assetsPath = CONFIG.assetsPath;
Vue.prototype.$contentPath = CONFIG.contentPath;
Vue.prototype.$maxUsers = CONFIG.maxUsers;
Vue.prototype.$appMode = CONFIG.appMode;

// DEV settings
/*
    "baseURL": "http://theremworld/",
    "apiURL": "http://theremworld/src/api/",
    "compURL": "http://theremworld/src/components/",
    "srcURL": "http://theremworld/src/",
    "assetsPath": "http://theremworld/src/assets/",
    "contentPath": "http://theremworld/src/content/",
    "appMode": "dev"
*/

/*
// Staging settings
    "baseURL": "https://staging.theremworld.com/",
    "apiURL": "https://staging.theremworld.com/api/",
    "compURL": "https://staging.theremworld.com/components/",
    "srcURL": "https://staging.theremworld.com/src/",
    "assetsPath": "https://staging.theremworld.com/assets/",
    "contentPath": "https://staging.theremworld.com/content/",
    "appMode": "stage"
*/

/*
// Production settings
    "baseURL": "https://theREMworld.com/",
    "apiURL": "https://theREMworld.com/api/",
    "compURL": "https://theREMworld.com/components/",
    "srcURL": "https://theREMworld.com/src/",
    "assetsPath": "https://theREMworld.com/assets/",
    "contentPath": "https://theREMworld.com/content/",
    "appMode": "prod"
*/

/*
//Google adsense
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
*/

Vue.use(VueRouter);
const router = new VueRouter({
    routes,
    mode: 'history',
    base: '/',
});

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

Vue.use(Buefy, {
    defaultIconPack: 'fas'
});

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        idUser: '',
        firstName: '',
        lastName: '',
        eMail: '',
        loginType: '',
        userAuth: false,
        userRole: 'Visitor',
        numLogins: 0,
        isInvited: false,
        lastLoginDate: '',
    },

    mutations: {
        authUser(state, userData) {
            state.idUser = userData.idUser;
            state.firstName = userData.firstName;
            state.lastName = userData.lastName;
            state.eMail = userData.eMail;
            state.userAuth = userData.isAuthenticated;
            state.userRole = userData.userRole;
            state.numLogins = userData.numLogins;
            state.isInvited = userData.isInvited;
            state.lastLoginDate = userData.lastLoginDate;
        },
    },

    actions: {
        signIn({ commit }, userData) {
            commit('authUser', userData);
        },
        
        signOut({ commit }) {
            localStorage.removeItem('expires_in')   /// clear expires in  
            localStorage.removeItem('user')
            commit ('authUser', {
                idUser: '',
                firstName: '',
                lastName: '',
                eMail: '',
                isAuthenticated: false,
                loginType: '',
                userRole: 'Visitor',
                numLogins: 0,
                isInvited: false,
                lastLoginDate: ''
            })
        },

    },

    getters: {
        userAuth: state => {
            return state.userAuth;
        },

        getUserName: state => {
            return state.firstName;
        },

        getUserID: state => {
            return state.idUser;
        },

        getLoginType: state => {
            return state.loginType;
        },

        userRole: state => {
            return state.userRole;
        },

        isUserInvited: state => {
            return state.isInvited;
        },

        numLogins: state => {
            return state.numLogins;
        },

        lastLoginDate: state => {
            return state.lastLoginDate;
        }
    }
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
});

