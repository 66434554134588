<template>
  <section>
    <b-modal :active="isActive" has-modal-card :can-cancel='cArray'>
      <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Sign In</p>
              <button type="button" class="delete" @click="closeThis"></button>
          </header>
          <section class="modal-card-body">
              <b-field label="User ID">
                  <b-input
                    ref='focusMe'
                    v-model="userID"
                    placeholder="Your your user ID"
                    required
                  ></b-input>
              </b-field>
              <b-field label="Password">
                  <b-input
                    type="password"
                    v-model="password"
                    password-reveal
                    placeholder="Your password"
                    required>
                  </b-input>
              </b-field>
              <p class="alert-msg">{{ message }}</p>
              <b-button type="is-text" @click="forgotPassword">Forgot password</b-button>
              <div class="center">
                <div class="is-flex is-align-items-center">
                    <hr class="solid" />
                    <h1> OR </h1>
                    <hr class="solid" />
                </div>
                    <google-auth @authToken='googleSignIn'></google-auth>
              </div>
          </section>
          <footer class="modal-card-foot">
              <b-button label="Close" @click="closeThis"></b-button>
              <b-button
                focused
                label="Login"
                type="is-success"
                @click="signinUser"
              ></b-button>
          </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>

import GoogleAuth from './GoogleAuth.vue';

export default {

    components: {
      'google-auth': GoogleAuth,
    },

    props: ["message"],

    emits: ["closeSignIn", "loginUser", "signinSuccess"],

    metaInfo: {
        title: 'theREMworld Sign In',
        meta: [
            {hid: 'description', name: 'description', content: 'Sign in to your REM world. Capture your dream journal, analyze dreams and learn all you need to know about this mysterious world we dive into when we sleep'}
        ]
    },

    data() {
      return {
        isActive: true,
        clientID: "354043053509-tlqu5asq312eqgc9oon145drhon7fc46.apps.googleusercontent.com",
        appId: "297109589120715",
        userID: "",
        email: "",
        password: "",
        isSocial: false,
        name: "",
        personalID: "",
        msg: this.message,
        user: [],
        form: {},
        cArray: [['escape', 'x'], true],
        isRedirect: false,
      };
    },

    watch: {
      message: function () {
        this.msg = this.message;
      },
    },

    methods: {
      closeThis() {
        this.$emit("closeSignIn");
        this.isActive = false;
      },

      googleSignIn(idToken) {
          let decodeToken = JSON.parse(atob(idToken.split('.')[1])); // Parse token to JSON object
          
          this.userID = decodeToken.email;
          this.isSocial = true;

          this.signinUser();
      },

      signinUser() {
          this.$http.get(this.$apiURL + "signinUser.php", {
              params: { uID: this.userID, pwd: this.password, isSocial: this.isSocial },
            }).then((response) => {
                this.logConsole(response.data);
                if (response.data.status == true) {
                    this.populateUserData(response.data.data);
                } else {
                    this.showAlert(response.data.message);
                }
            }).catch((error) => {
                //localStorage.removeItem('user');  /// removed user from login
                this.showAlert("Error: userSignIn - " + error); // error handling
            });
      },

      populateUserData(userData) {
          this.user.idUser = userData.idUser;
          this.user.firstName = userData.firstName;
          this.user.lastName = userData.lastName;
          this.user.eMail = userData.email;
          this.user.userRole = userData.userRole;
          this.user.numLogins = userData.numLogins;
          this.user.isInvited = userData.isInvited;
          this.user.isAuthenticated = true;
          this.isActive = false;

          /*
          // Set the cookie for auto login
          const now = new Date()
          const expirationDate = new Date(now.getTime() + 1440 * (60 * 1000))
          localStorage.setItem('expires_in', expirationDate);
          localStorage.setItem('user', JSON.stringify(this.user));
          */

          this.$store.dispatch("signIn", this.user);
          this.logConsole("App populate user data: ", this.user);
          this.$emit("signinSuccess");
          this.$router.push('/');   // Push to homepage URL
      },

      forgotPassword() {
        if (this.userID.length < 1) {
          this.showAlert("Please enter the user ID");
        } else {
          this.$http.get(this.$apiURL + "getUserProfile.php", {
              params: { uID: this.userID },
            }).then((response) => {
              this.logConsole(response.data);
              let resetForm = {
                uID: "",
                email: "",
                resetURL: this.$baseURL + "reset/",
              };
              if (response.data.status == true) {
                if (response.data.data[0].email.length == 0) {
                  this.showAlert(
                    "No email address is linked to this user ID. Please contact Support"
                  );
                } else {
                  resetForm.uID = response.data.data[0].idUser;
                  resetForm.email = response.data.data[0].email;
                  this.$http
                    .post(
                      this.$apiURL + "sendResetEmail.php",
                      JSON.stringify(resetForm)
                    )
                    .then((response) => {
                      this.logConsole("Email: ", response.data);
                      if (response.data.status == true) {
                        this.showAlert(
                          "A password reset e-mail has been sent to the e-mail associated with '" +
                            this.userID +
                            "' user ID. Please check your inbox. Depending upon the email filters, the email may be redirected to your junk or spam folder"
                        );
                      } else {
                        this.showAlert(
                          "An error has occured: " + response.data.message
                        );
                      }
                      this.isActive = false;
                      this.$emit("closeSignIn");
                    })
                    .catch((error) => {
                      this.showAlert("Error: userSignIn - " + error); // error handling
                    });
                }
              } else {
                this.showAlert(response.data.message);
              }
            })
            .catch((error) => {
              this.showAlert("Error: userSignIn - " + error); // error handling
            });
        }
      },
    },

    mounted() {
        this.$refs.focusMe.focus();
        this.isRedirect = (this.$route.params.action == 'signin');
    }
};
</script>

<style scoped>
.center {
  margin-left: 25%;
}

.alert-msg {
  color: red;
}

hr.solid {
  border: 1px solid #bbb;
  width: 20%;
}

</style>

