<template>
  <section>
    <b-modal :active='isActive' has-modal-card trap-focus :can-cancel='cArray'>
        <div v-if='allowSignup  && loaded' class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Sign Up</p>
              <button type="button" class="delete" @click='closeThis'></button>
            </header>
            <section class="modal-card-body">
                <b-field label="User ID or Email">
                    <b-tooltip
                      multilined
                      :label='promptTip'
                      position='is-right'
                      size='is-large'>
                      <b-input :disabled='isSubOrInvited' ref='focusMe' v-model="newUser.userID" :type='uType' :message='errMsg' placeholder="Your your ID or email" required @blur='checkName'></b-input>
                    </b-tooltip>
                    <span class='mx-2' :class='uType'> {{ errMsg }} </span>
                </b-field>
                <b-field label="Password">
                  <b-tooltip
                    multilined
                    label='We recommend creating a strong password. Minimum of 8-12 characters long password that includes numbers, letters'
                    position='is-right'
                    size='is-large'>
                    <b-input type="password" v-model="newUser.password" password-reveal placeholder="Your password" required></b-input>
                  </b-tooltip>
                </b-field>
                <b-field>
                    <vue-recaptcha @verify="validateCaptcha" :sitekey="googleSiteKey"></vue-recaptcha>
                </b-field>
                <p class='alert-msg'> {{ message }} </p>
                <div v-if='!isSubOrInvited' class='center'>
                  <div class='is-flex is-align-items-center'>
                    <hr class='solid mx-2'><h1>OR</h1><hr class='solid mx-2'>
                  </div>
                  <google-auth :terms='termsChk' @authToken='googleSignUp'></google-auth>
                </div>
              </section>
              <footer class="modal-card-foot align-center">
                  <b-field>
                      <b-checkbox v-model="termsChk"><span class='is-size-7'>By clicking here I agree to the <i><a href='https://theREMworld.com/terms' target='_blank'>terms and usage policies</a></i> of theREMworld</span></b-checkbox>
                  </b-field>
                  <b-button label="Close" @click='closeThis'></b-button>
                  <b-button :disabled='!(this.idValid && this.captchaValid && termsChk)' label="Register" type="is-success" @click='signupUser'></b-button>
              </footer>
        </div>
        <div v-if='!allowSignup && loaded' class="modal-card">
              <header class="modal-card-head">
                  <p class="modal-card-title">Sign Up</p>
                  <button type="button" class="delete" @click='closeThis'></button>
              </header>
              <section class="modal-card-body">
                  <p class='content alert-msg'>We have reached the maxium free sign ups at this time. Please try again later</p><br>
                  <p class='content'><b>Why are we restricting signups?</b><br>
                  <span class='content is-size-7'>It is our attempt to not show ads to our users or to make money by selling users' data. But, it takes money to host the site, develop, and manage this website and the mobile app. It also takes time, effort and money to continue to enhance the user experience, add meaningful content and improve the software to make dream analysis more accurate. For all this we rely on subscriptions by <i><b>gracious users like yourself</b></i>. Therefore, we plan to open up free sign ups in batches based on the revenue generated through subscriptions.</span></p>
                  <hr>
                  <p class='content'>You can provide us your e-mail address to be added to the queue and you will be notified with the respective batch on the basis of first-come-first-based selection. Or, you can skip the queue by choosing to subscribe</p><br>
                  <b-field label="Email:" horizontal>
                      <b-input v-model="userEmail"></b-input>
                      <b-button type='is-primary' @click='addNewLead'>Add me to the queue</b-button>
                  </b-field>
              </section>
              <footer class="modal-card-foot align-center">
                  <b-button label="Close" @click='closeThis'></b-button>
              </footer>
        </div>
    </b-modal>
  </section>
</template>

<script>
    import GoogleAuth from './GoogleAuth.vue';
    import { VueRecaptcha } from 'vue-recaptcha';

  export default ({

      components:{
          'vue-recaptcha': VueRecaptcha,
          'google-auth': GoogleAuth,
      },

      props: ['message', 'action', 'email', 'buyLink'],

      emits: ['closeSignUp', 'loginUser', 'signupSuccess', 'donate'],

      metaInfo: {
          title: 'theREMworld Sign Up',
          meta: [
              {hid: 'description', name: 'description', content: 'Sign up to the REM world. Capture your dream journal, analyze dreams and learn all you need to know about this mysterious world we dive into when we sleep'}
          ]
      },

      data () {
        return {
            isActive: true,
            //googleSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',      // For testing purposes
            //googleSiteKey: '6LfwCIwgAAAAAE_MEhZ7tnCSMwFmiTNvo3dx-zDF',    // Site key (V3 reCaptcha)
            googleSiteKey: '6LeTSd4gAAAAAC49TFXRcBoSX-mx5KalZJMYmsr5',      // Site key (V2 reCaptcha)
            clientID: '354043053509-tlqu5asq312eqgc9oon145drhon7fc46.apps.googleusercontent.com',
            //msg: this.message,
            uType: '',
            errMsg: '',
            user: [],
            newUser: {
                userID: '',
                password: '',
                firstName: '',
                lastName: '',
                isInvited: false,
                authType: 'User ID',
                userPic: '',
                socialToken: '',
            },
            idValid: true,
            userCount: 0,
            maxAllowed: 0,
            loaded: false,
            captchaValid: false,
            userEmail: '',
            cArray: [['escape', 'x'], true],
            isSub: false,
            subOption: '',
            termsChk: false,

            //reCaptcha verify
            // Site key - 6LeTSd4gAAAAAC49TFXRcBoSX-mx5KalZJMYmsr5
            // Secret key - 6LeTSd4gAAAAAKswCMKT15Mffh11PdOdb_TAycjC
            capVerify: {
                secret: '6LeTSd4gAAAAAKswCMKT15Mffh11PdOdb_TAycjC', // Secret key
                response: '',
            }
        }
      },

      computed: {
          allowSignup() {
              return (this.userCount < this.maxAllowed || this.isSub);
          },

          isSubOrInvited() {
              return (this.isInvited || this.isSub);
          },

          promptTip() {
              let promptText = '';
              if (this.isSub) {
                promptText = "To change this email, click on 'Close' and enter the new email on the previous screen";
              } else if (this.isInvited) {
                promptText = "Invitation email cannot be changed";
              } else {
                promptText = "We recommend using your e-mail as user ID. If you create a random ID, make sure your profile has an e-mail address to help in case of password reset or important notifications";
              }

              return promptText;
          }

      },

      /*
      watch: {
          message: function() {
              this.msg = this.message;
          }
      },
      */

      methods: {
          closeThis() {
              this.$emit('closeSignUp');
              this.isActive = false;
          },

          showTerms() {
              window.open(this.$baseURL + '/terms', '_blank');
          },

          validateCaptcha(res) {
              this.logConsole ("reCaptcha response: ", res);
              this.$http.get(this.$apiURL + "verifyreCaptcha.php", {
                  params: { response: res },
                }).then((response) => {
                    if (response.data.status == true) {
                        this.captchaValid = true;;
                    } else {
                        this.showAlert(response.data.message);
                    }
                }).catch((error) => {
                    this.showAlert("Error: " + error); // error handling
                });
          },

          googleSignUp(idToken) {

              let decodeToken = JSON.parse(atob(idToken.split('.')[1])); // Parse token to JSON object
              this.newUser.authType = 'google';
              this.newUser.userID = decodeToken.email;
              this.newUser.firstName = decodeToken.given_name;
              this.newUser.lastName = decodeToken.family_name;
              this.newUser.userPic = decodeToken.picture;
              this.newUser.socialToken = idToken;

              if (!this.termsChk) {
                  this.showAlert("Please confirm that you have read and agree to terms and conditions");
              } else {
                  this.signupUser();
              }
          },

          signupUser() {
              this.$http.post(this.$apiURL + 'signupUser.php', JSON.stringify(this.newUser)
                ).then((response) => {
                  this.logConsole(response.data);
                  if (response.data.status == true) {
                    this.populateUserData(response.data.data);
                    if (this.isSub) {
                        window.open(this.buyLink, '_blank');
                    }
                  } else {
                    this.showAlert(response.data.message);
                  }
                  this.$router.push('/');   // Push the user to their home page
                }).catch((error) => {
                  this.showAlert("Error: userSignUp - " + error);
                });
            },

          checkName() {
              this.$http.get(this.$apiURL + 'checkUserName.php', {
                params: { uID: this.newUser['userID'] } 
                }).then((response) => {
                  this.logConsole(response.data);
                  // false = user id does not exists and can be used
                  if (response.data.status == false) {
                    this.uType = 'has-text-success';
                    this.errMsg = this.newUser['userID'] + ' may be available';
                    this.idValid = true;
                  } else {
                    this.uType = 'has-text-danger';
                    this.errMsg = this.newUser['userID'] + ' is not available. Please select another';
                    this.idValid = false;
                  }
                }).catch((error) => {
                  this.showAlert("Error: userSignUp - " + error);
                });
            },

          populateUserData(userData) {
              this.user.idUser = userData.idUser;
              this.user.firstName = userData.firstName;
              this.user.lastName = userData.lastName;
              this.user.eMail = userData.email;
              this.user.userRole = userData.userRole;
              this.user.isAuthenticated = true;
              this.user.numLogins = 0;
              this.$store.dispatch('signIn', this.user);
              this.logConsole('App populate user data: ', this.user);
              
              // Send user welcome email to be implemented later

              this.isActive = false;
              this.$emit('closeSignUp');
              this.$router.push('/');   // Reset the link to home page
            },

          addNewLead() {
              if (this.userEmail.length == 0) {
                  this.showAlert("No e-mail has been provided");
              } else {
                  this.$http.get(this.$apiURL + 'saveNewLead.php', {
                      params: { email: this.userEmail }
                  }).then((response) => {
                      this.logConsole('new lead: ', response.data);
                      if (response.data.status) {
                          this.showAlert("You have been added to the queue. Please keep an eye on the invitation e-mail that may arrive as soon as we release the next batch of sign-ups");
                      } else {
                          this.showToastError("There was an error. Try again later");
                      }
                      this.closeThis();
                  }).catch((error) => {
                      this.showAlert("Error - " + error);
                  });
              }
          }
      },

      mounted() {
          // Check if this is an invitation sgn up
          if (this.$route.params.param2 == 'invite') {
              this.newUser.invitedBy = this.$route.params.param1;
              this.newUser.isInvited = true;
              this.user.isInvited = true;
          }

          // Check if this is a redirect from subscription page
          this.isSub = (this.action == 'sub');
          this.newUser.userID = this.email;

          // Get max allowed users
          this.$http.get(this.$apiURL + 'getUserCount.php'
              ).then((response) => {
                this.logConsole('User count: ', response.data);
                if (response.data.status == true) {
                    this.userCount = parseInt(response.data.data['userCount']);
                    this.maxAllowed = parseInt(response.data.data['maxAllowed']);
                    this.loaded = true;
                } else {
                    this.showAlert(response.data.message);
                }
              }).catch((error) => {
                  this.showAlert(error) // error handling
          });
      }
  });

</script>

<style scoped>

  .center {
    margin-left: 33%;
  }

  .alert-msg {
    color: red;
  }

  hr.solid {
    border: 1px solid #bbb;
    width: 20%;
  }

</style>