<template>
    <section class='container reset-box'>
        <div class='page-box'>
            <blank-header></blank-header>
        </div>
        <div class='box page-box'>
            <h1>Welcome back! You can reset your password here:</h1><br>
            <b-field label="New Password" horizontal>
                <b-input v-model='resetPwd.newPwd' type="password"></b-input>
            </b-field>
            <b-field label="Verify Password" horizontal>
                <b-input v-model='verifyPwd' type="password" password-reveal></b-input>
            </b-field>
            <hr>
            <b-button :disabled='resetPwd.newPwd !== verifyPwd' type='is-success' @click='updatePwd'>Submit</b-button>
        </div>
    </section>
</template>

<script>
    import BlankHeader from '../header/BlankHeader.vue';

    export default ({
        components: {
            'blank-header': BlankHeader,
        },

        metaInfo: {
            title: 'Reset your password',
            meta: [
                {hid: 'description', name: 'description', content: 'Reset your password'}
            ]
        },

        data() {
            return {
                resetPwd: {
                    uID: '',
                    token: '',
                    newPwd: ''
                },
                verifyPwd: '',
            }
        },

        methods: {

            updatePwd() {
                this.$http.post(this.$apiURL + 'resetPassword.php', JSON.stringify(this.resetPwd)
                    ).then((response) => {
                        this.logConsole("Reset: ", response.data);
                        if (response.data.status == true) {
                            this.showToastSuccess ("Your password has been updated. Please sign in using the new password");
                            this.$router.push('/');
                        } else {
                            this.showAlert("An error has occured: " + response.data.message);
                    }
                    }).catch((error) => {
                    this.showAlert("Error: Passwrod reset - " + error);   // error handling
                });
            }
        },

        mounted() {
            this.resetPwd.uID = this.$route.params.id;
            this.resetPwd.token = this.$route.params.token;
        }

    })
</script>

<style scoped>

    .page-box {
        margin: auto;
        padding: 25px;
    }

</style>
