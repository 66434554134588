<template>
  <section>
    <header id="header">
      <div class="logo">
        <a :href='this.$baseURL'>
          <img src="../../assets/logo.png" alt="theREMworld">
        </a>
      </div>
    </header>
  </section>
</template>

<script>

export default ({

})
</script>

<style scoped>
  #header {
    height: 50px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
  }

</style>