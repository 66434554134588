<template>
    <section>
        <div class='container search-box inner'>
          <div class="uderbox">
                <div class="columns">
                    <div class="column is-one-fifth">
                        <h4>Search dream symbols:</h4>
                    </div>
                    <div class="column">
                        <b-field type='is-primary'>
                            <b-input v-model='searchSymbols' placeholder="Enter one or more symbols separted by comma (e.g. snake, water)..." 
                                type="search"
                                rounded
                                icon-pack="fas"
                                icon-right="paper-plane"
                                icon-right-clickable
                                @icon-right-click='getSymbols'>
                            </b-input>
                        </b-field>
                    </div>
                </div>
            </div>
        </div>
        <div v-if='showResults' class='container box'>
            <b-button type='is-primary' size='is-small' @click='showResults=false' class="clsbtns"><i class="fas fa-times"></i></b-button>
          
            <div v-if='notFound.length > 0'>
                <p v-if='isVisitor' class='content'> {{ visitorMsg }} </p>
                <p v-if='!isVisitor' class='content'> {{ userMsg }} </p>
                <b-taglist>
                    <b-tag type='is-info' v-for='nf in notFound' :key='nf.id'> {{ nf }}</b-tag>
                </b-taglist>
                <div v-if='isVisitor' class='is-flex'>
                    <b-input v-model='userEmail' type='email' maxlength=35></b-input>
                    <b-button type='is-success' @click='saveRequest' class='mleft10'>Submit</b-button>
                </div>
            </div>
            <div v-if='found.length > 0'>
                <post-detail :rowNum=0 :postList='found'></post-detail>
            </div>
        </div>
    </section>
</template>

<script>
    import PostDetail from './PostDetail.vue';

    export default ({

        components: {
            'post-detail': PostDetail,
        },

        data () {
            return {
                searchSymbols: '',
                showResults: false,
                found: [],
                notFound: [],
                selected: {},
                userEmail: '',
                userMsg: 'The following symbols could not be found. Fear not! Our team continues to research and add new symbols on a regular basis',
                visitorMsg: 'The following symbols could not be found. Fear not! Our team continues to research and add new symbols on a regular basis. Enter your e-mail ID if you would like to be informed when these symbols are added',
            }
        },

        computed: {
            isVisitor() {
                return (this.$store.state.userRole.toLowerCase() == 'visitor');
            }
        },
    
        methods: {
            getSymbols() {
                if (this.searchSymbols.length == 0) {
                    this.showAlert("Please enter one or more symbol separated by comma (e.g. water, house, fire)")
                } else {
                    //Initialize the arrays
                    this.found = [],
                    this.notFound = [],
                    
                    this.$http.get(this.$apiURL + 'getSymbols.php', {
                        params: { symbolNames: this.searchSymbols, idUser: this.$store.state.idUser }
                    }).then((response) => {
                    this.logConsole('Search symbols: ', response.data);
                    response.data.data.forEach(this.parseData);
                    this.showResults = true;
                    }).catch((error) => {
                        this.showAlert("Error - " + error);
                    });
                }
            },

            parseData(sym) {
                if (sym.available) {
                    this.found.push({
                        'postTitle': sym.symbol,
                        'postText': sym.desc,
                    })
                } else {
                    this.notFound.push(sym.symbol);
                }
            },

            saveRequest() {
                this.$http.get(this.$apiURL + 'saveNewLead.php', {
                    params: { email: this.userEmail, symbols: this.notFound }
                }).then((response) => {
                    this.logConsole('new lead: ', response.data);
                    if (response.data.status) {
                        this.showToastSuccess("Email saved successfully");
                        this.userEmail = '';
                        this.showResults = false;
                    } else {
                        this.showToastError("There was an error. Try again later");
                    }
                }).catch((error) => {
                    this.showAlert("Error - " + error);
                });
            }
        }
    })


</script>

<style scoped>

    .search-box {
        width: 50%;
        margin: auto;
        padding-top: 25px;
        padding-bottom: 25px;
        background: #010410;
        max-width: 100% !important;
    }

    .search-box h4 {
        color: #fff;
        text-align: center;
        font-size: 20px;
    }

    input.input {
        background: #010410 !important;
    }

    .search-box .field {
        max-width: 600px !important;
        text-align: center;
        margin: 0 auto;
    }

</style>
