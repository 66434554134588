<template>
<section class="header-section">
    <div class="container">
        <b-navbar>
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="../../assets/logo.png"  @click="activeMenu(1)" alt="theREMworld">
            </b-navbar-item>

            <b-navbar-item tag="div" class="mtopmobile">
                <div>
                    <b-dropdown v-if='isAuth' :triggers="['click']" aria-role="list">
                        <template #trigger>
                            <a role="button" class='welcome-msg'>
                                <span>{{ userName }} </span>
                                <b-icon pack="fas" icon="fa-solid fa-caret-down" size='is-medium' type="is-white"></b-icon>
                            </a>
                        </template>
                        <b-dropdown-item aria-role="listitem" @click="activeMenu('profile')">Profile</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" @click="signOut">Sign Out</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown v-if='!isAuth' :triggers="['click']" aria-role="list">
                        <template #trigger>
                            <b-icon pack="fas" icon="fa-light fa-user" size="is-medium" type="is-light"></b-icon>
                        </template>
                        <b-dropdown-item aria-role="listitem" @click="showSignIn=true">Sign In</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" @click="showSignUp=true">Sign Up</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown :triggers="['click']" aria-role="list" position="is-bottom-left">
                        <template #trigger>
                            <b-icon pack="fas" icon="fa-light fa-share-alt" size="is-medium" type="is-light">Share</b-icon>
                        </template>
                        <b-dropdown-item aria-role="listitem">
                            <social-media></social-media>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-navbar-item>
        </template>
        

        <template #start >
            <b-navbar-item  v-for='navItem in navBar' :key='navItem.idPage'>
                <a v-if="navItem.isVisible=='Y'" :class="{ active: (navItem.idPage == selectedTab) }" @click='activeMenu(navItem.idPage)'>{{ navItem.dispLabel }}</a>
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item tag="div" class="dtopdesk">
                <div>
                    <b-dropdown v-if='isAuth' :triggers="['hover']" aria-role="list">
                        <template #trigger>
                            <a role="button" class='welcome-msg'>
                                <span>{{ userName }} </span>
                                <b-icon pack="fas" icon="fa-solid fa-caret-down" size='is-medium' type="is-white"></b-icon>
                            </a>
                        </template>
                        <b-dropdown-item aria-role="listitem" @click="activeMenu('profile')">Profile</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" @click="signOut">Sign Out</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" @click="activeMenu('faq')">Help!</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown v-if='!isAuth' :triggers="['hover']" aria-role="list">
                        <template #trigger>
                            <b-icon pack="fas" icon="fa-light fa-user" size="is-medium" type="is-light"></b-icon>
                        </template>
                        <b-dropdown-item aria-role="listitem" @click="showSignIn=true">Sign In</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" @click="showSignUp=true">Sign Up</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown :triggers="['hover']" aria-role="list" position="is-bottom-left">
                        <template #trigger>
                            <b-icon pack="fas" icon="fa-light fa-share-alt" size="is-medium" type="is-light">Share</b-icon>
                        </template>
                        <b-dropdown-item aria-role="listitem">
                            <social-media></social-media>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-navbar-item>
        </template>
        </b-navbar>
        <div>
            <div v-if='showSignIn'>
                <sign-in @closeSignIn='showSignIn=false'></sign-in>
            </div>
            <div v-if='showSignUp'>
                <sign-up @closeSignUp='showSignUp=false' @donate='acceptPayment'></sign-up>
            </div>
        </div>
    </div>
   </section> 
</template>

<script>
    import SignIn from './SignIn.vue';
    import SignUp from './SignUp.vue';
    import SocialMedia from './SocialMedia.vue';

    export default ({
        components: {
            'sign-in': SignIn,
            'sign-up': SignUp,
            'social-media': SocialMedia,
        },

        emits: ['routeLink'],

        data() {
            return {
                mBurger: false,
                showSignIn: false,
                showSignUp: false,
                selectedTab: '',
                navBar: [],
            }
        },

        computed: {
            isAuth() {
                return this.$store.getters.userAuth;
            },

            userName() {
                return this.$store.getters.getUserName;
            },

            userID() {
                return this.$store.getters.getUserID;
            },

        },

        watch: {
            userID: function() {
                this.getUserNavBar();
            }
        },

        methods: {
            activeMenu(pID) {
                //alert('Nab Bar - ' + pID);
                this.navBar.forEach( navItem => {
                    if (navItem.idPage == pID || navItem.routeLink == pID) {
                        //alert ('NavBar - routeLink --- ' + navItem.routeLink + ' / ' + navItem.routeProp + ' / ' + navItem.routeType);
                        this.$emit('routeLink', navItem.routeLink, navItem.routeProp, navItem.routeType)
                    }
                    this.selectedTab = pID;
                })
            },

            getUserNavBar() {
                //this.showAlert('User ID: ' + this.userID);
                this.$http.get(this.$apiURL + 'getNavbar.php', {
                    params: { uID: this.userID }
                    }).then((response) => {
                        this.logConsole('Navbar: ', response.data);
                        if (response.data.status == true) {
                            this.navBar = response.data.data;
                            this.selectedTab = this.navBar[0].idPage;
                        } else {
                            this.showToastError("Something went wrong. Try again");
                        }
                    }).catch((error) => {
                        this.logConsole('Navbar error: ', error);
                        this.showAlert(error) // error handling
                    });
            },

            signOut() {
                this.$store.dispatch('signOut');
                this.showSignIn = false;
            },

            acceptPayment() {
                this.$router.push('payment');
                this.showSignUp = false;
            }

        },

        mounted() {
            this.getUserNavBar();
            if (this.$route.params.action !== undefined) {
                this.showSignUp = this.$route.params.action.toLowerCase() == 'signup';
                this.showSignIn = this.$route.params.action.toLowerCase() == 'signin';
            }
        },

    });

</script>