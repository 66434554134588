<template>
    <section>
        <router-view />
    </section>
</template>

<script>

    export default ({
        data() {
            return {
                //
            }
        },

        metaInfo: {
            title: 'theREMworld',
            titleTemplate: 'theREMworld - %s',
            htmlAttrs: {
                lang: 'en-US'
            },
        },

        methods: {
            cookieWarning() {
                this.$buefy.snackbar.open({
                    duration: 5000,
                    message: 'We use cookies to enhance the user experience and performance of the site',
                })
            },

            getBrowserDetails() {

                var ua = navigator.userAgent;
                
                // For testing purposes
                //let pattern = /\w+\/\d+/gi;
                
                // Extract all browser names and version
                let pattern = /(opera|chrome|safari|firefox|msie|trident|edg)\/\d+/gi;
                let result = ua.match(pattern);

                // Initialize the variables
                let b = '', bName = '', bVer = 0, isSupported = true;
                if (/edg/i.test(result)) {
                    //95 or higher
                    b = ua.match(/edg\/\d+/gi).toString();
                    bName = 'Edge';
                    bVer = b.slice(b.indexOf("/") + 1);
                    isSupported = (bVer > 90);
                } else if (/(opr|opera)/i.test(result)) {
                    //47 and higher
                    b = ua.match(/(opera|opr)\/\d+/gi).toString();
                    bName = 'Opera';
                    bVer = b.slice(b.indexOf("/") + 1);
                    isSupported = (bVer > 47);
                } else if (/(firefox)/i.test(result)) {
                    //57 and higher
                    b = ua.match(/(firefox)\/\d+/gi).toString();
                    bName = 'Firefox';
                    bVer = b.slice(b.indexOf("/") + 1);
                    isSupported = (bVer > 57);
                }else if (/chrome/i.test(result)) {
                    //54 or higher
                    b = ua.match(/chrome\/\d+/gi).toString();
                    bName = 'Chrome';
                    bVer = b.slice(b.indexOf("/") + 1);
                    isSupported = (bVer > 54);
                } else if (/safari/i.test(result)) {
                    //9 and above
                    b = ua.match(/edg\/\d+/gi).toString();
                    bName = 'Safari';
                    bVer = b.slice(b.indexOf("/") + 1);
                    isSupported = (bVer > 9);
                } else {
                    b = 'Unknown browser';
                    bName = 'Unknown';
                    bVer = 0;
                    isSupported = false;
                }
                //alert (bName + ' - ' + bVer + ' - ' + isSupported);

                return {
                    name: bName,
                    version: bVer,
                    isSupported: isSupported
                };
            },

            /* Commenting out the autologin feature. Needs rework
            tryAutoLogin() {
                //const Token = localStorage.getItem('token');
                const user = localStorage.getItem('user');
                if (user) {
                    this.dispatch('signIn', JSON.parse(user));
                }
                const expirationDate = new Date(localStorage.getItem('expires_in'));
                const now = new Date();
                if (now > expirationDate) {
                    this.dispatch('signOut');
                    localStorage.removeItem('user')
                    return;
                }
            },
            */

        },

        mounted() {
            // Show cookies warning
            this.cookieWarning();

            // Check for screen size
            if (((window.innerWidth < 480) || (window.innerHeight < 600))) {
                this.showAlert("For the best mobile experience, download our app from the App or Play store");
            }

        },

        created() {
            /* Commenting out the autologin feature. Needs rework
            let user = [];
            user.idUser = '';
            user.firstName = '';
            user.lastName = '';
            user.eMail = '';
            user.isAuthenticated = false;
            user.userRole = 'Visitor';
            this.$store.dispatch('signIn', user);
            this.$store.dispatch('tryAutoLogin');
            */

            // Check browser version
            var browser = this.getBrowserDetails();
            if (!browser.isSupported) {
                this.showAlert("Browser detected - " + browser.name + " / " + browser.version + "<br><br>This browser is not supported and the website may not function properly. We recommend that you use one of the latest browsers");
            }
        },

    })

</script>

<style scoped>

</style>
