<template>
    <div ref="googleLoginBtn" />
</template>

<script>

  export default({

      emits: ['authToken'],

      data () {
          return {
              gClientId: '354043053509-tlqu5asq312eqgc9oon145drhon7fc46.apps.googleusercontent.com',
              clientid:'354043053509-tlqu5asq312eqgc9oon145drhon7fc46.apps.googleusercontent.com',
            }
      },

    methods: {
      
        async onSuccess(response) {
            console.log('Google Response: ', response.credential);
            this.$emit('authToken', response.credential);
        },

        OnFailure(error) {
          if (error.error !== 'popup_closed_by_user') {
            this.showAlert("Error signin in - " + error);
            this.logConsole('Google Sign in error: ', error);
          }
        },
    },

    mounted() {
        //const gClinetId = this.clientid;
        window.google.accounts.id.initialize ({
            client_id: this.clientid,
            callback: this.onSuccess,
            auto_select: true
        })

        window.google.accounts.id.renderButton(
            this.$refs.googleLoginBtn, {
                text: 'continue_with',
                //size: 'large',
                'width': 240,             // max width 400
                'height': 50,
                'theme': 'filled_blue',   // option : filled_black | outline | filled_blue
            }
        )
    },

  });

</script>