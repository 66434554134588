import HomePage from './components/HomePage.vue';
import ResetPassword from './components/auth/ResetPassword.vue';
import VisitorHome from './components/visitors/VisitorHome.vue';
/*
import SignIn from './components/auth/SignIn.vue';
import SignUp from './components/auth/SignUp.vue';
import ContactUs from './components/common/ContactUs.vue';
import AcceptPayment from './components/payments/StripePayment.vue';
import SubscribeUser from './components/payments/StripeSubscription.vue';
*/


export const routes = [
    //{ path: '/signin', component: SignIn },
    //{ path: '/signup/:id', component: HomePage },
    /*
    { path: '/contactus', component: ContactUs },
    { path: '/payment', component: AcceptPayment },
    { path: '/subscribe', component: SubscribeUser },
    { path: '/about', component: VisitorHome, props: {openLink: 'page', openProp: 'about', openType: 'page'} },
    */
    { path: '/privacy', component: VisitorHome, props: {openLink: 'page', openProp: 'privacy', openType: 'page'} },
    { path: '/terms', component: VisitorHome, props: {openLink: 'page', openProp: 'terms', openType: 'page'} },
    { path: '/reset/:id/:token', component: ResetPassword },
    { path: '/:action', component: HomePage },
    { path: '/:action/:param1', component: HomePage },
    { path: '/:action/:param1/:param2', component: HomePage },
    { path: '/', component: HomePage },
    { path: '*', component: HomePage },
]

/* Example for dynamic routing
const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about/:companyid',
        name: 'About',
        props: true,
        component: () =>
            import ('../views/About.vue')
    },
]

// Routes experiment with pre-render
export const routes = [
    //{ path: '/signin', component: SignIn },
    //{ path: '/signup/:id', component: HomePage },
    { path: '/contact', component: ContactUs },
    { path: '/payment', component: AcceptPayment },
    { path: '/donate', component: AcceptPayment },
    { path: '/about', component: VisitorHome, props: {openLink: 'page', openProp: 'about', openType: 'page'}},
    { path: '/privacy', component: VisitorHome, props: {openLink: 'page', openProp: 'privacy', openType: 'page'} },
    { path: '/terms', component: VisitorHome, props: {openLink: 'page', openProp: 'terms', openType: 'page'} },
    { path: '/blogs', component: VisitorHome, props: {openLink: 'postlist', openProp: 'blog', openType: 'component'}},
    { path: '/themes', component: VisitorHome, props: {openLink: 'postlist', openProp: 'theme', openType: 'component'}},
    { path: '/symbols', component: VisitorHome, props: {openLink: 'symbollist', openProp: '', openType: 'component'}},
    { path: '/further', component: VisitorHome, props: {openLink: 'page', openProp: 'reading', openType: 'page'}},
    { path: '/reset/:id/:token', component: ResetPassword },
    { path: '/:action', component: HomePage },
    { path: '/:action/:param1', component: HomePage },
    { path: '/:action/:param1/:param2', component: HomePage },
    { path: '/', component: HomePage },
    { path: '*', component: HomePage },
]
*/