<template>
    <section>
        <div>
            <nav-bar @routeLink='showComponent'></nav-bar>
        </div>
        <div>
            <component v-if='!withProp' :is='compName'></component>
            <component v-if='withProp' :is='compName' :compParams='compProp'></component>
        </div>
        <div class='footer rem-footer'>
            <div class='container'>
            <div class='columns is-ancestor'>
                <div class='column is-parent'>
                    <article class='tile is-child'>
                        <ul class='ftrli'>
                            <li>
                                <a @click="showComponent('page', 'about', 'page')">About Us</a>
                            </li>
                            <li>
                                <a @click="showComponent('page', 'privacy', 'page')">Privacy Policy</a>
                            </li>
                            <li>
                                <a @click="showComponent('page', 'terms', 'page')">Terms</a>
                            </li>
                        </ul>
                    </article>
                </div>
                <div class='column ftrright'>
                    © theREMworld. All Rights Reserved
                </div>
            </div>
            </div>
        </div>
    </section>
</template>

<script>
import NavBar from '../auth/NavBar.vue';

export default ({
    props: ['openLink', 'openProp', 'openType'],

    components: {
        //'banners-page': BannersPage,
        'nav-bar': NavBar,
    },

    data() {
        return {
            compName: ()=> import('./BannersPage.vue'),
            withProp: false,
            compProp: '',

            visitorComps: [
                { name: '/', comp: ()=> import('./BannersPage.vue') },
                { name: 'page', comp: ()=> import('../common/ShowPage.vue') },
                { name: 'postlist', comp: ()=> import('../common/PostList.vue') },
                { name: 'symbollist', comp: ()=> import('../common/SymbolList.vue') },
                { name: 'donate', comp: ()=> import('../payments/StripePayment.vue') },
                { name: 'subscribe', comp: ()=> import('../payments/StripeSubscription.vue') },
                { name: 'contact', comp: ()=> import('../common/ContactUs.vue') },
            ]
        }
    },

    metaInfo: {
        title: 'Visitor',
        meta: [
            {name: 'description', content: 'Welcome to theREMworld. Learn about the mysterious world of our dreams. Get your dreams analyzed by our artificial intelligence or other users to find out the hidden symbols and meaning in your dreams'}
        ]
    },

    methods: {
        showComponent(rLink, rProp, rType) {
            //alert ('Visitor home - link- ' + rLink + ' -prop- ' + rProp + ' -type- ' + rType);
            this.compProp = rProp;

            for (let x=0; x < this.visitorComps.length; ++x) {
                if (rLink.toLowerCase() == this.visitorComps[x].name.toLowerCase()) {
                    this.compName = this.visitorComps[x].comp;
                }
            }
            this.isPage = (rType.toLowerCase() == 'page');
            this.withProp = rProp.length > 0;
        },

        /* Dynamic load of components did not work as expected. The above code is a work around
            const AsyncComponent = () => ({
                component: import(rLink),
            })
        */

    },

    mounted() {
        if (this.openLink !== undefined) {
            this.showComponent(this.openLink, this.openProp, this.openType);
        }
    }

})

</script>

<style scoped>

    .spaced {
        margin: 5px;
    }

    .qotd-box {
        position: absolute;
        top: 30%;
        left: 70%;
        z-index: 1;
    }

    .rem-footer {
        background-color: midnightblue;
        padding: 25px;
        margin: 0px; 

    }
    .container.content {
        min-height: 100vh;
    }

    .ftrli {
        margin:0px; 
        padding:0px; 
        list-style:none;
    }

    .ftrli li {
        margin:0px; 
        padding:0px 10px; 
        display:inline-block;
    }

    .ftrli li a {
        color:rgba(255,255,255,.7);
    }

    .ftrli li a:hover {
        color:rgba(255,255,255,1);
    }

    .ftrright {
        text-align:right;
        color:rgba(255,255,255,.7);
    }

</style>
