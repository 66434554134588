<template>
    <section>
        <div v-if='isModeStage'>
            <b-modal v-model='showStage' :can-cancel='false'>
                <div class='container box'>
                    <h1>You have landed on the staging site of <i>theREMworld</i>. This site is used for development of new features and testing by the technical team. <br><br> The user website is available at '<a href="https://theREMworld.com">https://theREMworld.com</a>'</h1><br>
                    <b-button type='is-success' @click='showStage=false'>Okay</b-button>
                </div>
            </b-modal>
        </div>
        <div v-if='!isAuth'>
            <visitor-home></visitor-home>
        </div>
        <div v-if='isAdmin'>
            <admin-home></admin-home>
        </div>
        <div v-if='isUser'>
            <user-home></user-home>
        </div>
    </section>
</template>

<script>
    import UserHome from './users/UserHome.vue';
    import AdminHome from './admin/AdminHome.vue';
    import VisitorHome from './visitors/VisitorHome.vue';

    export default ({
        components: {
            'user-home': UserHome,
            'admin-home': AdminHome,
            'visitor-home': VisitorHome,
        },

        metaInfo: {
            title: 'theREMworld',
            titleTemplate: 'theREMworld - %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                {charset: 'utf-8'},
                {hid: 'description', name: 'description', content: 'Welcome to theREMworld. Learn about the mysterious world of our dreams. Get your dreams analyzed by our artificial intelligence or other users to find out the hidden symbols and meaning in your dreams'},
                {name: 'viewport', content: 'width=device-width, initial-scale=1'}
            ]
        },

        data() {
            return {
                showStage: true,
                promptKey: '',
            }
        },

        computed: {
            isAuth() {
                return this.$store.getters.userAuth;
            },

            isUser() {
                return (this.$store.state.userRole.toLowerCase() == 'user');
            },

            isAdmin() {
                return (this.$store.state.userRole.toLowerCase() == 'admin');
            },

            isVisitor() {
                return (this.$store.state.userRole.toLowerCase() == 'visitor');
            },

            isModeStage() {
                return (this.$appMode == 'stage');
            }
        },

        methods: {
            checkAccess() {
                if (this.promptKey == "Thi$ is not a phrase 2 remember") {
                    this.showStage = false;
                } else {
                    this.showAlert ('Invalid pass phrase');
                }
            },
            
            // Intercept the browser refresh
            preventNav(event) {
                if (!this.isAuth) { return; }
                event.preventDefault();
                event.returnValue = '';
            },
            
        },

        // Add event handler to intercept browser refresh
        beforeMount() {
            window.addEventListener("beforeunload", this.preventNav)
        },

        // Remove the event handler we added
        beforeDestroy() {
            window.removeEventListener("beforeunload", this.preventNav);
        },

    })

</script>

<style scoped>

    h1, p {
        text-align: center;
        vertical-align: middle;
        align-content:center;
        padding: 20px;
        margin: auto;
    }

</style>
