<template>
    <section>
        <div>
            <nav-bar @routeLink='showComponent'></nav-bar>
        </div>
        <p class='block warning position-btm'>The information on this site cannot be construed as medical advise nor as therapy. The content is user generated mixed with artificial intelligence. <strong><i>theREMworld</i></strong> cannot guarantee that the content will not have any inaccuracies or will be complete</p>
        <div class=''>
            <symbol-search></symbol-search>
            <div class='spcing'>
            <component :is='compName'></component>
            </div>
        </div>
    </section>
</template>

<script>
    import NavBar from '../auth/NavBar.vue';
    import SymbolSearch from '../common/SymbolSearch.vue';

    export default ({
        components: {
            'nav-bar': NavBar,
            'symbol-search': SymbolSearch,
        },

        data() {
            return {
                compName: ()=> import('./DreamJournal.vue'),

                userComps: [
                    { name: '/', comp: ()=> import('./DreamJournal.vue') },
                    { name: 'profile', comp: ()=> import('./ManageProfile.vue') },
                    { name: 'analyze', comp: ()=> import('./AnalyzeDreams.vue') },
                    { name: 'circle', comp: ()=> import('./DreamsCircle.vue') },
                    { name: 'contact', comp: ()=> import('../common/ContactUs.vue') },
                    //{ name: 'subscribe', comp: ()=> import('../payments/StripeSubscription.vue') },
                    { name: 'postlist', comp: ()=> import('../common/PostList.vue') },
                    { name: 'symbollist', comp: ()=> import('../common/SymbolList.vue') },
                    { name: 'faq', comp: ()=> import('../common/FaqPage.vue') },
                ],
            }
        },

        metaInfo: {
            title: 'User Home',
            meta: [
                {name: 'description', content: 'Home page of authenticated user'}
            ]
        },

        methods: {
            showComponent(rLink, rProp, rType) {
                //alert (rLink + ' -prop- ' + rProp + ' -type- ' + rType);
                this.compProp = rProp;

                for (let x=0; x < this.userComps.length; ++x) {
                    if (rLink.toLowerCase() == this.userComps[x].name.toLowerCase()) {
                        this.compName = this.userComps[x].comp;
                    }
                }
                this.isPage = (rType.toLowerCase() == 'page');
                this.withProp = rProp.length > 0;
            },
        },

        mounted() {
            if (this.$store.state.numLogins == 0 && this.$store.state.isInvited) {
                this.showComponent('analyze', '', '');
            } else if (this.$store.state.numLogins == 0) {
                //this.showComponent('profile', '', '');
                this.showToastSuccess("Welcome to theREMworld! You can update your profile by selecting 'Profile' from top right menu")
            }
        }

    });

</script>